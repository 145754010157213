import styled from "styled-components"

import { FormControl, InputLabel, Select } from "@material-ui/core"

import {
  DAY_MULTIPLIER,
  friendlySeconds,
  HOUR_MULTIPLIER,
  MINUTE_MULTIPLIER,
} from "src/data/guestCommunication/logic/guestCommunicationLogic"
import { useFetchGuestCommunicationTriggers } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import { GuestCommunicationTrigger } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { spacing } from "src/ui/spacing"

const TIMING = [
  { name: "2 weeks before", seconds: DAY_MULTIPLIER * 14 * -1 },
  { name: "1 week before", seconds: DAY_MULTIPLIER * 7 * -1 },
  { name: "3 days before", seconds: DAY_MULTIPLIER * 3 * -1 },
  { name: "2 days before", seconds: DAY_MULTIPLIER * 2 * -1 },
  { name: "1 day before", seconds: DAY_MULTIPLIER * 1 * -1 },
  { name: "2 hours before", seconds: HOUR_MULTIPLIER * 2 * -1 },
  { name: "1 hour before", seconds: HOUR_MULTIPLIER * 1 * -1 },
  { name: "30 minutes before", seconds: MINUTE_MULTIPLIER * 30 * -1 },
  { name: "15 minutes before", seconds: MINUTE_MULTIPLIER * 15 * -1 },
  { name: "At time of", seconds: 0 },
  { name: "15 minutes after", seconds: MINUTE_MULTIPLIER * 15 },
  { name: "30 minutes after", seconds: MINUTE_MULTIPLIER * 30 },
  { name: "1 hour after", seconds: HOUR_MULTIPLIER * 1 },
  { name: "2 hours after", seconds: HOUR_MULTIPLIER * 2 },
  { name: "1 day after", seconds: DAY_MULTIPLIER * 1 },
  { name: "2 days after", seconds: DAY_MULTIPLIER * 2 },
  { name: "3 days after", seconds: DAY_MULTIPLIER * 3 },
  { name: "1 week after", seconds: DAY_MULTIPLIER * 7 },
  { name: "2 weeks after", seconds: DAY_MULTIPLIER * 14 },
]

export interface IScheduledForChangeEvent {
  triggerOffsetSeconds: number
  trigger: GuestCommunicationTrigger
}

export function ScheduledFor({
  triggerOffsetSeconds,
  trigger,
  onChange,
}: {
  triggerOffsetSeconds: number
  trigger: GuestCommunicationTrigger
  onChange: (schedule: IScheduledForChangeEvent) => void
}) {
  const fetchTriggers = useFetchGuestCommunicationTriggers({})
  const triggers = fetchTriggers.data

  const unknownTrigger = !triggers?.find((t) => t.trigger === trigger)
  const unknownTiming = !TIMING.find((t) => t.seconds === triggerOffsetSeconds)

  function handleChange({
    trigger,
    triggerOffsetSeconds,
  }: IScheduledForChangeEvent) {
    onChange({ trigger, triggerOffsetSeconds })
  }

  if (!triggers) {
    return null // TODO WEB-502: Add loading state
  }

  return (
    <Box>
      <FormControl variant="outlined">
        <InputLabel htmlFor="trigger-select">Trigger</InputLabel>
        <Select
          native
          value={trigger}
          onChange={(e) => {
            const newTrigger = e.target.value as GuestCommunicationTrigger
            handleChange({
              triggerOffsetSeconds,
              trigger: newTrigger,
            })
          }}
          label="Trigger"
          inputProps={{
            name: "trigger",
            id: "trigger-select",
          }}
        >
          {unknownTrigger && (
            <option value={trigger} disabled>
              {trigger}
            </option>
          )}
          {triggers?.map((trigger) => {
            return (
              <option key={trigger.trigger} value={trigger.trigger}>
                {trigger.trigger_name}
              </option>
            )
          })}
        </Select>
      </FormControl>

      <FormControl variant="outlined">
        <InputLabel htmlFor="whentosend-select">When to send</InputLabel>
        <Select
          native
          value={triggerOffsetSeconds}
          onChange={(e) => {
            const seconds = Number(e.target.value)
            handleChange({
              trigger,
              triggerOffsetSeconds: seconds,
            })
          }}
          label="When to send"
          inputProps={{
            name: "whentosend",
            id: "whentosend-select",
          }}
        >
          {unknownTiming && (
            <option value={triggerOffsetSeconds} disabled>
              {friendlySeconds(triggerOffsetSeconds)}{" "}
              {triggerOffsetSeconds > 0 ? "after" : "before"}
            </option>
          )}
          {TIMING?.map((timing) => {
            return (
              <option key={timing.name} value={timing.seconds}>
                {timing.name}
              </option>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
`
