import { Fragment, useState } from "react"

import { Switch } from "@material-ui/core"

import {
  channelsFriendlyName,
  friendlySeconds,
} from "src/data/guestCommunication/logic/guestCommunicationLogic"
import {
  useDeleteGuestCommunicationRule,
  useFetchGuestCommunicationChannels,
  useFetchGuestCommunicationTriggers,
  usePatchGuestCommunicationRule,
} from "src/data/guestCommunication/queries/guestCommunicationQueries"
import {
  GuestCommunicationTrigger,
  IGuestCommunicationRule,
} from "src/data/guestCommunication/types/guestCommunicationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"

export function GuestCommunicationTable({
  orgId,
  rowData = [],
}: {
  orgId: string
  rowData: IGuestCommunicationRule[]
}) {
  const fetchChannels = useFetchGuestCommunicationChannels({})
  const fetchTriggers = useFetchGuestCommunicationTriggers({})
  const { navigate } = useRouter()

  const deleteRule = useDeleteGuestCommunicationRule()
  const patchRule = usePatchGuestCommunicationRule()
  const [deleteRuleId, setDeleteRuleId] = useState("")

  const header = [
    <div key={"misc"}></div>,
    <div key={"name"}>Name</div>,
    <div key={"when"}>When</div>,
    <div key={"send-through"}>Send through</div>,
    <div key={"misc2"}></div>,
  ]

  function handleDelete(ruleId: string) {
    setDeleteRuleId(ruleId)
  }

  function handleCloseDialog() {
    setDeleteRuleId("")
    deleteRule.reset()
  }

  function getRuleUrl(rule: IGuestCommunicationRule) {
    return Routes.OrgGuestComEditRule.location(rule.id).pathname
  }

  function getRuleByIndex(index: number): IGuestCommunicationRule | undefined {
    return rowData?.[index]
  }

  function triggerFriendlyName(trigger: GuestCommunicationTrigger) {
    return (
      fetchTriggers.data?.find((t) => t.trigger === trigger)?.trigger_name ||
      trigger
    ).toLocaleLowerCase()
  }

  function handleToggle(rule: IGuestCommunicationRule, enabled: boolean) {
    patchRule.mutate({
      orgId,
      ruleId: rule.id,
      data: { enabled },
    })
  }

  function handleRowClick(index: number) {
    const rule = getRuleByIndex(index)
    if (!rule?.id) {
      return
    }
    navigate(getRuleUrl(rule))
  }

  const rows = rowData.map((rule) => {
    return (
      <Fragment key={rule.id}>
        <div>
          <Switch
            checked={rule.enabled}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => {
              handleToggle(rule, e.target.checked)
            }}
          />
        </div>

        <div>
          <InternalLink
            to={getRuleUrl(rule)}
            onClick={(e) => e.preventDefault()}
          >
            {rule.name}
          </InternalLink>
        </div>

        <div>
          {friendlySeconds(rule.trigger_offset_seconds)}{" "}
          {rule.trigger_offset_seconds === 0
            ? ""
            : rule.trigger_offset_seconds < 0
              ? "before"
              : "after"}{" "}
          {triggerFriendlyName(rule.trigger)}
        </div>

        <div>
          {!fetchChannels.isLoading &&
            !fetchChannels.isError &&
            channelsFriendlyName(
              rule.communication_channels,
              fetchChannels.data
            )}
        </div>

        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MoreButton
              onDelete={() => handleDelete(rule.id)}
              onEdit={() => navigate(getRuleUrl(rule))}
            />
          </div>
        </div>
      </Fragment>
    )
  })

  return (
    <div>
      <GridTable
        header={header}
        rows={rows}
        templateColumns={`auto repeat(${header.length - 1}, 1fr)`}
        onRowClick={handleRowClick}
        useFallbackResponsiveMode
      />

      <ConfirmDialog
        title="Delete message?"
        description="This message will be permanently deleted."
        confirmButtonProps={{ color: "destructive" }}
        confirmLabel="Delete"
        open={!!deleteRuleId}
        responsive={false}
        onClose={handleCloseDialog}
        onConfirm={() => {
          deleteRule.mutate(
            { orgId, ruleId: deleteRuleId },
            { onSuccess: handleCloseDialog }
          )
        }}
        loading={deleteRule.isLoading}
      >
        {!!deleteRule.isError && (
          <MBanner type="error" fullWidth>
            Something went wrong when deleting rule
          </MBanner>
        )}
      </ConfirmDialog>
    </div>
  )
}
