import {
  GuestCommunicationChannel,
  IGuestCommunicationChannelsResponse,
} from "src/data/guestCommunication/types/guestCommunicationTypes"

export const MINUTE_MULTIPLIER = 60
export const HOUR_MULTIPLIER = MINUTE_MULTIPLIER * 60
export const DAY_MULTIPLIER = HOUR_MULTIPLIER * 24

export function channelsFriendlyName(
  ruleChannels: GuestCommunicationChannel[],
  channelMetadata: IGuestCommunicationChannelsResponse | undefined
) {
  return ruleChannels
    .map((c) => {
      const foundMetadata = channelMetadata?.channels?.find(
        (metadata) => metadata.channel === c
      )
      if (foundMetadata) {
        return foundMetadata.channel_name
      }
      return c
    })
    .join(", ")
}

export interface ISecondsParsed {
  days: number
  hours: number
  minutes: number
  seconds: number
}

/* Parse seconds into units of days, hours, minutes & seconds */
export function parseSeconds(inputSeconds: number): ISecondsParsed {
  // Inspired by https://stackoverflow.com/a/36099084/955014
  let seconds = Math.abs(inputSeconds)
  const days = Math.floor(seconds / DAY_MULTIPLIER)
  seconds -= days * DAY_MULTIPLIER
  const hours = Math.floor(seconds / HOUR_MULTIPLIER)
  seconds -= hours * HOUR_MULTIPLIER
  const minutes = Math.floor(seconds / MINUTE_MULTIPLIER)
  seconds -= minutes * MINUTE_MULTIPLIER
  return { days, hours, minutes, seconds }
}

export function lowestDenominator(p: ISecondsParsed) {
  return p.seconds
    ? 1
    : p.minutes
      ? MINUTE_MULTIPLIER
      : p.hours
        ? HOUR_MULTIPLIER
        : p.days
          ? DAY_MULTIPLIER
          : 0
}

export function friendlySeconds(inputSeconds: number) {
  if (inputSeconds === 0) {
    return "At time of"
  }
  const parsed = parseSeconds(inputSeconds)
  function unit2str(
    unit: "days" | "hours" | "minutes" | "seconds" | string,
    value: number
  ) {
    const plural = value !== 1
    const unitMap: { [key: string]: string } = {
      days: "day",
      hours: "hour",
      minutes: "minute",
      seconds: "second",
    }
    return (unitMap[unit] || "unknown") + (plural ? "s" : "")
  }
  return Object.entries(parsed)
    .filter((o) => !!o[1])
    .map((o) => `${o[1]} ${unit2str(o[0], o[1])}`)
    .join(", ")
}
