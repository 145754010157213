import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { GuestCommunicationAddButton } from "src/components/GuestCommunication/GuestCommunicationAddButton"
import { IGuestCommunicationRuleTemplate } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Icon from "src/ui/icons/scheduled-message-bubble.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function GuestCommunicationEmptyState({
  templates,
  loading,
}: {
  templates?: IGuestCommunicationRuleTemplate[]
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyState
      icon={<Icon />}
      title="No messages yet"
      body={
        <BodyBox>
          <MText color="secondary">
            {t(langKeys.messaging_description)}{" "}
            <InternalLink to={Routes.MonitoringSettings.location().pathname}>
              {t(langKeys.settings)}
            </InternalLink>
          </MText>
          <GuestCommunicationAddButton
            templates={templates}
            loading={loading}
          />
        </BodyBox>
      }
    />
  )
}

const BodyBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`
