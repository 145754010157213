import styled from "styled-components"

import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function GuestCommunicationLoading() {
  return (
    <Box>
      <MSkeleton variant="rect" />
      <MSkeleton variant="rect" />
      <MSkeleton variant="rect" />
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  margin-top: ${spacing.XL};
`
