import { GuestCommunication } from "src/components/GuestCommunication/GuestCommunication"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"

export function OrganizationGuestComs() {
  const { org } = useOrganization()
  const activeOrgId = org.id

  return (
    <>
      <GuestCommunication orgId={activeOrgId} />
    </>
  )
}
