import { useState } from "react"

import { AxiosError } from "axios"

import { RuleForm } from "src/components/GuestCommunication/RuleForm"
import {
  usePatchGuestCommunicationRule,
  usePostGuestCommunicationRule,
} from "src/data/guestCommunication/queries/guestCommunicationQueries"
import {
  IGuestCommunicationError,
  IGuestCommunicationRule,
  IPostGuestCommunicationRule,
} from "src/data/guestCommunication/types/guestCommunicationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"

export function EditRuleDialog({
  orgId,
  rule,
}: {
  orgId: string
  rule: IGuestCommunicationRule
}) {
  const patchRule = usePatchGuestCommunicationRule()

  async function handleSave(data: IPostGuestCommunicationRule) {
    return patchRule.mutateAsync({ ruleId: rule.id, orgId, data })
  }

  return (
    <RuleDialog
      title="Edit message"
      rule={rule}
      onSave={handleSave}
      loading={patchRule.isLoading}
    />
  )
}

export function CreateRuleDialog({
  orgId,
  rule,
}: {
  orgId: string
  rule: IPostGuestCommunicationRule
}) {
  const createRuleMutation = usePostGuestCommunicationRule()

  async function handleSave(data: IPostGuestCommunicationRule) {
    return createRuleMutation.mutateAsync({ orgId, data })
  }

  return (
    <RuleDialog
      title="New message"
      rule={{ ...rule, enabled: true }}
      onSave={handleSave}
      loading={createRuleMutation.isLoading}
    />
  )
}

function RuleDialog({
  title,
  rule,
  loading,
  onSave,
}: {
  title: string
  rule: IPostGuestCommunicationRule
  loading?: boolean
  onSave: (
    rule: IPostGuestCommunicationRule
  ) => Promise<IGuestCommunicationRule | void>
}) {
  const { navigate } = useRouter()

  const [error, setError] = useState("")

  const open = !!rule
  const [dirty, setDirty] = useState(false)
  const [showConfirmExit, setShowConfirmExit] = useState(false)

  function handleClose() {
    if (dirty) {
      setShowConfirmExit(true)
    } else {
      navigate(Routes.OrgGuestCom.location())
    }
  }

  async function handleSubmit(
    formData: IPostGuestCommunicationRule,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    handleSubmitError: any
  ) {
    const data: IPostGuestCommunicationRule = {
      name: formData.name,
      trigger: formData.trigger,
      trigger_offset_seconds: formData.trigger_offset_seconds,
      communication_channels: formData.communication_channels,
      content: formData.content,
      enabled: !!formData.enabled,
    }
    try {
      await onSave(data)
      navigate(Routes.OrgGuestCom.location())
    } catch (e) {
      const err = e as AxiosError<IGuestCommunicationError>
      if (err.response?.data.error_key === "unsupported_shortcode") {
        const node = (
          <MBanner type="error">
            '{err.response?.data.context.guest_communication_shortcode}':{" "}
            {err.response?.data.message}
          </MBanner>
        )
        handleSubmitError(node)
      } else {
        setError("Error saving rule")
      }
    }
  }

  if (!rule) {
    return null
  }

  const formId = "rule-form"

  return (
    <>
      <MDialog
        title={title}
        open={open}
        formId={formId}
        onClose={handleClose}
        loading={loading}
        confirmLabel="Save"
        error={error}
      >
        <RuleForm
          ruleInitial={rule}
          onUpdate={({ isDirty }) => setDirty(isDirty)}
          onSubmit={handleSubmit}
          formId={formId}
        />
      </MDialog>

      <DiscardChangesDialog
        open={showConfirmExit}
        onClose={() => {
          setShowConfirmExit(false)
        }}
        onConfirm={() => {
          setDirty(false)
          navigate(Routes.OrgGuestCom.location())
        }}
      />
    </>
  )
}
